<template>
    <div class="data-details-view">
        <back-home :stuInfo="detailList"  :type="detailData.type"></back-home>
        <data-detail-info
            :type="detailData.type"
            :listQuery="listQuery"
            :stuInfo="detailList"
            :checked="checked"
            :stuSel="stuSel"
            @inquiry="inquiry"
            @reset="reset"
            @changeAllSel="changeAllSel"
        ></data-detail-info>
        <data-detail-list
            :type="detailData.type"
            :list="detailList.list"
            @selChange="selChange"
        ></data-detail-list>
        <btn-option
            v-if="detailData.type == 'edit'"
            @save="save"
            @cancellation="cancellation"
        ></btn-option>
    </div>
</template>
<script>
import BackHome from "./BackHome/index.vue";
import DataDetailInfo from "./DataDetailInfo/index.vue";
import DataDetailList from "./DataDetailList/index.vue";
import BtnOption from "./BtnOption.vue";
import { CheckRecordModel } from "@/models/RepastCheckRecord";

export default {
    name: "DataDetails",
    components: {
        BackHome,
        DataDetailInfo,
        DataDetailList,
        BtnOption,
    },
    props: {
        detailData: Object,
    },
    data() {
        return {
            detailList: {},
            detailList2: {},
            checked: false,
            listQuery: {
                studentName: "",
                studyNo: "",
            },
            list: [],
            stuSel: []
        };
    },
    created() {
        this.init();
        console.log(this.list, "list",this.detailData);
    },
    activated() {
        this.listQuery.studentName = ""
        this.listQuery.studyNo = ""
        this.getList()
    },
    methods: {
        async init() {
            await this.initAssignment();
            await this.getList()
            await this.selChange()
        },
        initAssignment() {},
        async getList() {
            const checkRecordModel = new CheckRecordModel();
            await checkRecordModel.getCheckRecordDayDetail({
                id: this.detailData.id,
            }).then((res) => {
                console.log(res.data.data, '天详情')
                if (res.data.code === '200') {
                    this.detailList = res.data.data;
                    this.detailList2 = this._.cloneDeep(this.detailList)
                    console.log(this.detailList.list,'this.detailList');

                }
            });

        },

        /**
         * @Description: 查询
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-11 15:07:16
         */
        inquiry() {
            console.log(this.listQuery, "查询");
            const checkRecordModel = new CheckRecordModel();
            checkRecordModel.getCheckRecordDayDetailDel({
                id: this.detailData.id,
                studentName: this.listQuery.studentName,
                studyNo: this.listQuery.studyNo,
            }).then((res) => {
                console.log(res.data.data, '学生')
                if (res.data.code === '200') {
                    this.detailList.list = res.data.data;
                    console.log(this.detailList.list,'this.detailList');

                }
            });
        },
        /**
         * @Description: 重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-11 15:07:36
         */
        reset() {
            this.listQuery = {
                studentName: "",
                studyNo: "",
            };
            this.inquiry();
            console.log(this.listQuery, "重置");
        },
        /**
         * @Description: 全选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-11 15:32:30
         */
        changeAllSel() {
            let _this = this;
            this.checked = !this.checked;

            this.detailList.list.map((item) => {
                item.checkStatus = _this.checked ? 1 : 0;
            });

            this.detailList.list.forEach(i => {
                const res = this.detailList2.list.find(j => j.id == i.id)
                if (res != -1) {
                    res.checkStatus = i.checkStatus
                }
            });

            this.stuSel = this.detailList2.list.filter((item) => item.checkStatus);
        },
        /**
         * @Description: 单个选中
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-11 15:11:22
         */
        selChange() {
            let pointer = 0;
            let remainder = this.detailList.list.filter((item) => !item.checkStatus);

            if (remainder.length == pointer) {
                this.checked = true;
            }

            if (remainder.length > pointer) {
                if (this.checked) {
                    this.checked = false;
                }
            }

            this.detailList.list.forEach(i => {
                const res = this.detailList2.list.find(j => j.id == i.id)
                if (res != -1) {
                    res.checkStatus = i.checkStatus
                }
            });

            this.stuSel = this.detailList2.list.filter((item) => item.checkStatus);

            console.log(this.detailList.list, "this.list");
        },
        /**
         * @Description: 取消
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-11 15:42:43
         */
        cancellation() {
            this.$eventDispatch('back')
        },
        /**
         * @Description: 保存
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-11 15:42:03
         */
        save() {
            const checkRecordModel = new CheckRecordModel();
            checkRecordModel.getCheckRecordDayDetailSave({
                id: this.detailList.id,
                realityNum: this.stuSel.length,
                list: this.detailList2.list,
            }).then((res) => {
                console.log(res,'保存');
                if (res.data.code === '200') {
                    this.$message.success('保存成功')
                    this.getList()
                    this.$eventDispatch('back')
                }else{
                    this.$message.error(res.data.msg)
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.data-details-view {
    background-color: #EDF0F7;
}
</style>
